<script>
  import { fly } from "svelte/transition";
  import { generateUniqueID } from "../utils";

  import Input from "./input.svelte";
  import Button from "./button.svelte";
  import { isNumeric } from "../utils";

  export let calcSettings = {
    id: "",
    inputs: [],
    calcHandler: function(a, b) {}
  };

  let inputValues = {};
  let inputErrors = false;
  let result = null;

  function handleChange() {
    if (!isNumeric(this.value)) return;
    if (inputErrors) return (inputErrors = false);

    inputValues[this.id] = this.value;
  }

  function handleSubmit() {
    // check if inputValues is complete
    if (
      !Object.keys(inputValues).length ||
      Object.keys(inputValues).length !== calcSettings.inputs.length
    )
      return (inputErrors = true);

    result = calcSettings.calcHandler(inputValues);
  }
</script>

<style type="text/postcss">
  section {
    @apply mx-auto mt-8 p-4 max-w-5xl bg-gray-200 rounded shadow-md leading-tight;
  }

  section:first-of-type {
    @apply mt-0;
  }

  h2 {
    @apply text-2xl font-semibold text-gray-700;
  }
  p.example {
    @apply text-sm my-1 text-teal-500;
  }
  .form {
    @apply mt-2 mb-8 flex flex-col;
  }

  p.error {
    @apply font-semibold text-red-500;
  }

  .result {
    @apply pt-3 text-gray-800 border-t-2 border-gray-400;
  }

  .result em {
    @apply not-italic text-xl text-indigo-400 font-semibold;
  }

  @screen md {
    section {
      @apply mt-16 px-12 py-6;
    }

    p.example {
      @apply text-base;
    }

    .form {
      @apply mt-12 flex-row items-end;
    }
  }
</style>

<section>
  <h2>{calcSettings.title}</h2>
  <p class="example">
    <i>Example: {calcSettings.example}</i>
  </p>
  <form class="form" on:submit|preventDefault={handleSubmit}>
    {#each calcSettings.inputs as { label, placeholder }}
      <Input
        id={calcSettings.id}
        {label}
        {placeholder}
        changeHandler={handleChange} />
    {/each}
    <Button />
  </form>
  {#if inputErrors}
    <p class="error" in:fly={{ y: 20, duration: 250 }}>
      Please correct your value(s) entered
    </p>
  {/if}
  {#if result}
    <div class="result" in:fly={{ y: 50, duration: 500 }}>
      <p>
        Result:
        <em>
          {#if calcSettings.percentResult}{result}%{:else}{result}{/if}
        </em>
      </p>
    </div>
  {/if}
</section>

<script>
  export let label = "input";
  export let id = "";
  export let placeholder = "";
  export let changeHandler = () => {};

</script>

<style type="text/postcss">
  div {
    @apply mt-4 flex flex-col;
  }
  label {
    @apply mb-2 text-gray-600;
  }
  input {
    @apply px-3 py-3 w-full text-white bg-gray-700 border-2 border-solid border-gray-700 outline-none rounded;
  }
  input:focus {
    @apply border-indigo-300;
  }

  @screen md {
    div {
      @apply mr-20;
    }
  }
</style>

<div>
  <label for={label}>{label}:</label>
  <input
    id={label}
    type="number"
    {placeholder}
    required
    on:change={changeHandler} />
</div>

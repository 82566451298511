<script>
  import calcArr from "../calcData";
  import Calc from "./calc.svelte";
</script>

<style type="text/postcss">
  main {
    @apply my-6 px-2 h-full font-body;
  }

  @screen md {
    main {
      @apply my-20 px-16;
    }
  }
</style>

<main>
  {#each calcArr as calcSettings}
    <Calc {calcSettings} />
  {/each}
</main>

export function isNumeric(num) {
  return !isNaN(num);
}

export function generateUniqueID() {
  return Math.random().toString(36).substr(2, 9);
}

export function formatKeys(obj) {
  let clonedObj = {};

  // parseInt all properties
  for (let prop in obj) {
    clonedObj[prop] = parseInt(obj[prop]);
  }

  return clonedObj;
}

export function roundToTwo(num) {
  return parseFloat(num).toFixed(2);
}

export function pipe(...fns) {
  return function piped(arg) {
    return fns.reduce((val, fn) => fn(val), arg);
  };
}

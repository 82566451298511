<script>
  export let name = "Calculate";
</script>

<style type="text/postcss">
  button {
    @apply mt-8 px-6 py-3 text-white font-semibold uppercase tracking-wider bg-teal-400 rounded shadow;
  }

  button:focus,
  button:hover {
    @apply bg-indigo-500;
  }

  @screen lg {
    button {
      @apply mt-0 ml-24;
    }
  }
</style>

<button type="submit">{name}</button>

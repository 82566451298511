import { pipe, formatKeys, generateUniqueID } from "./utils";

let calcArray = [
  {
    id: generateUniqueID(),
    title: "Calculate what X percent is of a number",
    example: "What is 60% of 1,200",
    percentResult: false,
    inputs: [
      {
        label: "Percentage",
        placeholder: "60"
      },
      {
        label: "Number",
        placeholder: "1200"
      }
    ],
    calcHandler: pipe(
      formatKeys,
      function({ Percentage: percent, Number: num }) {
        return (num / 100) * percent;
      }
    )
  },
  {
    id: generateUniqueID(),
    title: "Take a percentage from a value",
    example: "What is amount remaining by taking 20% from 300",
    percentResult: false,
    inputs: [
      {
        label: "Percentage",
        placeholder: "20"
      },
      {
        label: "Amount",
        placeholder: "300"
      }
    ],
    calcHandler: pipe(
      formatKeys,
      function({ Percentage: percent, Amount: amount }) {
        return amount * (1 - percent / 100);
      }
    )
  },
  {
    id: generateUniqueID(),
    title: "Find the percentage of what X is to Y",
    example: "As a percentage, what is 75 in comparison to 300",
    percentResult: true,
    inputs: [
      {
        label: "Number",
        placeholder: "75"
      },
      {
        label: "Total",
        placeholder: "300"
      }
    ],
    calcHandler: pipe(
      formatKeys,
      function({ Number: num, Total: total }) {
        return num * (100 / total);
      }
    )
  },
  {
    id: generateUniqueID(),
    title: "Percentage increase or decrease of X to Y",
    example: "What is the percentage increase from 75 to 90",
    percentResult: true,
    inputs: [
      {
        label: "Starting",
        placeholder: "75"
      },
      {
        label: "Final",
        placeholder: "90"
      }
    ],
    calcHandler: pipe(
      formatKeys,
      function({ Starting: start, Final: final }) {
        return (final - start) * (100 / start);
      }
    )
  }
];

export default calcArray;
